body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.message_outer_wrapper{
  border: 1px solid #000;
  display: table;
  padding: 0 !important;
  min-height: 200px;
}
.message_wrapper{
  border: 1px solid #000;
  display: table-row;
  min-height: 200px;
}
.left_wrapper{
  display: table-cell;
  width: 20%;
  min-height: 200px;
  border-right: 1px solid;
}

.right_wrapper{
  display: table-cell;
  width: 75%;
  min-height: 200px;
  text-align: left;
  border-left: 1px solid;
  padding: 20px;
}